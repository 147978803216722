@media screen and (max-width: 1301px) {
  .head2-child2-left {
    width: 470px;
  }
  .main2-home-txt1 {
    font-size: 0.84em;
  }
  .main1-warenkorb-detail-txt1 a {
    font-size: 1.3em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 1.3em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1201px) {
  .head2-child2-left {
    width: 400px;
  }
  .main2-home-txt1 {
    font-size: 0.74em;
  }
  .main2-warenkorb-detail-child1-left2-txts a {
    font-size: 0.85em;
  }
  .main1-warenkorb-detail-dad {
    width: 80%;
  }
  #btn-kasse {
    font-size: 81%;
  }
}
@media screen and (max-width: 1025px) {
  .head2-child2-left {
    width: 320px;
  }
  .main2-home-txt1 {
    font-size: 0.72em;
  }
  .main2-warenkorb-detail-child1-left2-txts a {
    font-size: 0.8em;
    margin-right: 15px;
  }
  .main2-warenkorb-detail-child1-right2-txt1 {
    margin-left: 15px;
  }
}
@media screen and (max-width: 1012px) {
  .main4-home-child1-right img {
    width: 320px;
    height: 240px;
  }
}
@media screen and (max-width: 992px) {
  .head2-child2-left {
    width: 250px;
  }
  .main4-home-child1-right img {
    width: 300px;
    height: 200px;
  }
  .main4-home-left-txt1 h2 {
    font-size: 1.3em;
    width: 100%;
  }
  .main4-home-left-txt1 h3 {
    font-size: 1.1em;
    width: 100%;
  }
  .main4-home-left-box-1 span {
    font-size: 0.88em;
  }
  .main4-home-left-box-2 span {
    font-size: 0.88em;
  }
  .main4-home-left-box-3 span {
    font-size: 0.88em;
  }
  .main4-home-left-box-1 {
    width: auto;
  }
  .main4-home-left-box-2 {
    width: auto;
  }
  .main4-home-left-box-3 {
    width: auto;
  }
  .main2-home-txt1 {
    font-size: 0.6em;
  }
  .main2-warenkorb-detail-child1-left2-txts a {
    font-size: 0.7em;
    margin-right: 15px;
  }
  .main2-warenkorb-detail-child1-right2-txt1 {
    margin-left: 15px;
  }
  #btn-kasse {
    font-size: 68%;
  }
}
@media screen and (max-width: 920px) {
  .main1-warenkorb-detail-dad {
    width: 87%;
  }
}
@media screen and (max-width: 912px) {
  .main4-home-child1-right img {
    width: 250px;
    height: 200px;
  }
  .main2-warenkorb-detail-child1-left2-txts a {
    font-size: 0.65em;
    margin-right: 15px;
  }
  .main2-warenkorb-detail-child1-right2-txt1 {
    /* margin-left: 15px; */
  }
}
@media screen and (min-width: 850px) {
  .head2-icn-res {
    display: none;
  }
  .head2-res {
    display: none !important;
  }
  .main1-product-detail-child1 {
    display: none !important;
  }
  .main1-product-detail-icn-res {
    display: none !important;
  }
  .head2-res-cntr {
    display: none !important;
  }
}
@media screen and (max-width: 850px) {
  .head-1-child1-right-icns {
    display: none;
  }
  .head1-dad {
    display: flex;
    justify-content: center;
  }
  .head-1-child1-left-txt2,
  .head-1-child1-left-txt3 {
    border: none;
  }
  .head2-dad {
    width: 100%;
  }
  .head2-child1-left {
    display: none;
  }
  .head2-child2-left {
    display: none;
  }
  .head2-icn-res {
    font-size: 1.9em;
    color: #777;
    line-height: 2;
    margin-top: 10px;
  }
  .head2-child1-right {
    display: none !important;
  }
  .head2-res-brd1 {
    height: 30px;
    vertical-align: middle;
    margin: 20px 7.5px 0 7.5px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .head2-icn1-res {
    margin-top: 10px;
  }
  .head2-icn1-res i {
    /* margin:0 10px; */
    font-size: 1.6em;
    color: rgba(102, 102, 102, 0.85);
    line-height: 2.2;
  }
  .head2-icn2-res {
    margin-top: -5px;
  }
  .head2-icn2-res i {
    color: #ee7f01;
    font-size: 2.5em;
  }
  .head2-child1-left-logo {
    /* width: 100%;
        height:100%;
        margin-right: 30px; */
    display: none !important;
  }
  .head-3 {
    display: none !important;
  }
  .main1-home-txts {
    display: none !important;
  }
  .main4-home-dad {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .main4-home-left-txt1 h2 {
    font-size: 1.5em;
    text-align: center;
  }
  .main4-home-left-txt1 h3 {
    font-size: 1.25em;
    text-align: center;
  }
  .main4-home-child1-right img {
    margin-top: 50px;
  }
  .main4-home-left-boxs {
    flex-wrap: wrap;
    width: 100%;
  }
  .main4-home-left-box-1 {
    margin-top: 15px;
  }
  .main4-home-left-box-2 {
    margin-top: 15px;
  }
  .main4-home-left-box-3 {
    margin-top: 15px;
  }
  .main5-home-dad {
    flex-wrap: wrap;
  }
  .main5-home-child1-res {
    width: 100%;
  }
  .main5-home-child2-res {
    width: 100%;
    margin-top: 30px;
  }
  .main1-product-child2 {
    display: none;
  }
  .main1-product-child1 {
    text-align: center;
  }
  .main1-product-dad {
    display: flex;
    justify-content: center;
  }
  .main2-home-box1 {
    width: 32%;
    margin: 15px auto 0 auto;
  }
  .main1-product-detail-dad {
    display: flex;
    flex-wrap: wrap;
  }
  .main1-product-detail-child-left {
    width: 100%;
  }
  .main1-product-detail-child-left-img1 {
    text-align: center;
  }
  .main1-product-detail-child1 {
    color: #777;
    text-transform: uppercase;
    font-size: 1em;
    font-family: "poppins-b";
    text-align: center;
    width: 100%;
  }
  .main1-product-detail-child-right {
    width: 100%;
  }
  .main1-product-detail-icn-res i {
    padding-right: 15px;
    color: #777;
    font-size: 10px;
  }
  .main2-warenkorb-detail-dad {
    flex-direction: column;
  }
  .main2-warenkorb-detail-child1 {
    width: 100%;
    border: none;
  }
  .main2-warenkorb-detail-child1-left1 {
    width: 100%;
  }
  .main2-warenkorb-detail-child1-right2-txt1 {
    width: 100%;
    font-size: 0.7em;
  }
  .main2-warenkorb-detail-child2 {
    width: 100%;
  }
  .main2-warenkorb-detail-child2-right {
    width: 100%;
  }
  .main1-warenkorb-detail-dad {
    width: 85%;
  }
  .main1-warenkorb-detail-txt1 a {
    font-size: 1.1em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 1.1em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 1.1em;
  }
  .main1-warenkorb-detail-numbr {
    font-size: 1em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-numbr2 {
    font-size: 1em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-icn1 {
    font-size: 1em;
  }
  .main1-kasse-dad {
    flex-direction: column;
  }
  .main1-kasse-child1 {
    width: 100%;
  }
  .main2-kasse-child2 {
    width: 100%;
  }
  .main1-kasse-child1-txt1 {
    text-align: center;
  }
  #btn-kasse {
    font-size: 100%;
  }
  .main1-kasse-child1-2-res {
    width: 100%;
  }
  .chevron-l-icn,
  .chevron-r-icn {
    display: none;
  }
  .rs-picker-toggle-wrapper {
    max-width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .main4-home-left-txt1 h2 {
    font-size: 1.2em;
  }
  .main4-home-left-txt1 h3 {
    font-size: 1em;
  }
  .main4-home-left-box-1 span {
    font-size: 0.8em;
  }
  .main4-home-left-box-2 span {
    font-size: 0.8em;
  }
  .main4-home-left-box-3 span {
    font-size: 0.8em;
  }
  .main1-warenkorb-detail-txt1 a {
    font-size: 1em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 1em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 1em;
  }
  .main1-warenkorb-detail-numbr {
    font-size: 0.75em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-numbr2 {
    font-size: 0.75em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-icn1 {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 662px) {
  .main1-warenkorb-detail-txt1 a {
    font-size: 0.7em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 0.7em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 0.7em;
  }
  .main1-warenkorb-detail-numbr {
    font-size: 0.75em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-numbr2 {
    font-size: 0.75em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-icn1 {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 660px) {
  .head-1-child1-left-txt1,
  .head-1-child1-left-txt2,
  .head-1-child1-left-txt3 {
    font-size: 12px;
  }
  .main2-home-box1 {
    width: 42%;
  }
  .main2-home-txt1 {
    font-size: 0.72em;
  }
  .main2-anfrage-dad {
    flex-direction: column;
  }
  .main2-anfrage-child2 {
    width: 100%;
    margin-bottom: 20px;
  }

  .main2-anfrage-put input {
    width: 100%;
  }
  .main2-anfrage-select {
    width: 100%;
  }
  .main2-anfrage-txtarea {
    border: 0.5px solid rgba(0, 0, 0, 0.09);
    background-color: rgba(0, 0, 0, 0.05);
    color: #555;
    border-radius: 10px;
    width: 100%;
    margin-top: 5px;
  }
  .main2-buy-txtarea {
    border: 0.5px solid rgba(0, 0, 0, 0.09);
    background-color: rgba(0, 0, 0, 0.05);
    color: #555;
    border-radius: 10px;
    width: 100%;
    margin-top: 5px;
  }
  /* .main2-anfrage-txt1{
        display: none;
    }
    .main2-anfrage-btn1{
        display: none;
    } */
}
@media screen and (max-width: 576px) {
  .head-1-child1-left-txt1,
  .head-1-child1-left-txt2,
  .head-1-child1-left-txt3 {
    font-size: 9px;
  }
  /* .head2-icn-res{
        margin-left: 15px;
    } */
  .main2-home-box1 {
    width: 80%;
  }
  .main2-home-txt1 {
    font-size: 0.9em;
  }
  .main1-product-detail-child1-right-txt2 {
    font-size: 1.3em;
  }
  .main1-product-detail-child1-right-txt3 {
    font-size: 1.1em;
  }
  .main1-impressum-txt1 {
    font-size: 1.4em;
  }
  .main1-impressum-txt3 {
    font-size: 1.4em;
  }
  .main1-impressum-txt4 {
    font-size: 1.2em;
  }
  .main1-warenkorb-detail-txt1 a {
    font-size: 0.6em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 0.6em;
  }
  .main1-warenkorb-detail-txt2 a {
    font-size: 0.6em;
  }
  .main1-warenkorb-detail-numbr {
    font-size: 0.55em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-numbr2 {
    font-size: 0.55em;
    margin-top: 2px;
  }
  .main1-warenkorb-detail-icn1 {
    font-size: 0.55em;
  }
  .txt-preis-res {
    display: none;
  }
  .rs-picker-toggle-wrapper {
    max-width: 76%;
  }
}
@media screen and (min-width: 550px) {
  .main2-warenkorb-detail-child1-left2-res {
    display: none !important;
  }
  .main1-warenkorb-detail-res {
    display: none !important;
  }
}
@media screen and (max-width: 550px) {
  .main2-warenkorb-detail-child1-left2 {
    display: none !important;
  }
  .main2-warenkorb-detail-child1-right2-txt1 {
    margin: 3px 0 0 5px;
    font-size: 0.7em;
  }
  .main2-warenkorb-detail-child1-left2-txt3 {
    width: 10px;
  }
  .main2-warenkorb-detail-child1-left2-res {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }
  .main1-warenkorb-detail {
    display: none !important;
  }
  .main1-warenkorb-detail {
    display: none !important;
  }
  .main1-warenkorb-detail-txt1 a {
    font-size: 1.6em;
    color: #222;
  }
  .main1-new-child1-txt1 {
    font-size: 1.2em;
  }
  .main1-new-child1-txt2 {
    font-size: 1.2em;
  }
  #btn-kasse {
    font-size: 90%;
  }
  /* .head1-dad-down-right-inpt-1 input{
        width: 92% !important;
    } */
  .head1-dad-down-right-inpt-anzahl-form .rs-input {
    max-width: 97%;
  }
}
@media screen and (max-width: 470px) {
  .head-1-child1-left-txt1,
  .head-1-child1-left-txt2,
  .head-1-child1-left-txt3 {
    font-size: 7.1px;
  }
  #btn-kasse {
    font-size: 80%;
  }
  .rs-picker-toggle-wrapper {
    max-width: 69%;
  }
  .head1-dad-down-right-inpt-anzahl-form .rs-input {
    margin-left: 10px;
    max-width: 93%;
  }
}
@media screen and (max-width: 400px) {
  .head-1-child1-left-txt1,
  .head-1-child1-left-txt2,
  .head-1-child1-left-txt3 {
    font-size: 6px;
  }
  .main1-new-child1-txt1 {
    font-size: 1em;
  }
  .main1-new-child1-txt2 {
    font-size: 1em;
  }
  #btn-kasse {
    font-size: 75%;
  }
  .rs-input {
    width: 93% !important;
  }
}
@media screen and (max-width: 390px) {
  .rs-picker-toggle-wrapper {
    max-width: 66%;
  }
  .head1-dad-down-right-inpt-anzahl-form .rs-input {
    margin-left: 10px;
  }
}
