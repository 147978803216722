.main1-home {
  background-color: white;
  /* height: 132px; */
  padding-bottom: 30px;
}
.main1-home-dad {
  margin-top: 30px;
}
.main1-home-txt1 div {
  color: #006ad0;
  font-size: 1.25em;
  font-weight: bolder;
  font-family: "poppins-b";
}
.main1-home-txt2 {
  text-align: right;
  padding: 0 0 10px 0;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: rgba(102, 102, 102, 0.85);
}
.main1-home-txt2 a {
  color: rgba(102, 102, 102, 0.85);
}
.main1-home-txt2:hover {
  text-align: right;
  padding: 0 0 10px 0;
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: black;
}
.main1-home-txt2 a:hover {
  color: black;
}
.main2-home {
  background-color: white;
  padding-bottom: 50px;
}
.main2-home-dad {
  text-align: left;
}
.main2-home-box1 {
  width: 23.8%;
  /* height: 354px; */
  border: 1px solid rgba(230, 224, 224, 0.85);
  border-top: 0.5px solid rgba(230, 224, 224, 0.15);
  border-bottom: 2px solid rgba(212, 205, 205, 0.95);
  background-color: white;
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
  /* margin:15px 5.7px 0 5.7px; */
}
.main2-home-box1:hover {
  box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: 0.5s;
  width: 23.8%;
}
.main2-home-box1 img {
  width: 100%;
  min-height: 287px;
}
.main2-home-txt1 {
  font-weight: bold;
  font-size: 0.92em;
  color: #555;
  letter-spacing: 0.05em;
  text-align: center;
  /* width: 272px; */
}
.main2-home-txt2 {
  font-size: 0.7em;
  letter-spacing: 0.05em;
  color: #555555;
  text-align: center;
  margin-bottom: 15px;
  /* width: 272px; */
}
.main2-home-txt1-pro {
  opacity: 0.7;
  font-size: 0.75em;
  color: #777;
}
.main2-home-txt2-pro {
  font-size: 0.9em;
  color: #555;
  padding-bottom: 20px;
}
.main2-home-txt2:hover {
  font-size: 0.7em;
  letter-spacing: 0.05em;
  color: #ee7f01;
  margin-bottom: 15px;
}
.main3-home {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50%;
  background-image: url(../img/back-img.png);
  /* height: 506px; */
  padding-bottom: 50px;
}
.main3-home-dad {
  margin-top: 50px;
  text-align: center;
}
.main3-child1 {
  font-size: 1.7em;
  line-height: 1.3;
  color: #ee7f01;
  font-family: "poppins-b";
}
.main3-child1-txt-1 {
  color: #006ad0;
  font-weight: bolder;
  font-size: 1.6em;
  line-height: 1.3;
  font-family: "poppins-b";
  padding-bottom: 30px;
}
.main3-child1-txt2 {
  font-size: 1.16em;
  line-height: 1.8;
  font-weight: 550;
  color: #777;
  margin-bottom: 30px;
}
.main3-child1-txt3 {
  margin-bottom: 30px;
  font-size: 1.16em;
  /* line-height: 1.8; */
  font-weight: 550;
  color: #777;
}
.main3-child1-btn button {
  border: 2px solid #ee7f01;
  background: none;
  font-size: 1em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  color: #ee7f01;
  padding: 6px 20px;
  font-family: "poppins-b";
}
.main3-child1-btn button:hover {
  border: 2px solid #ee7f01;
  background: none;
  font-family: "poppins-b";
  font-size: 1em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  color: white;
  padding: 6px 20px;
  background-color: #ee7f01;
}
.main4-home {
  background-color: rgb(247, 247, 247);
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 60px), 0 100%);
  /* height: 400px; */
  padding-bottom: 50px;
}
.main4-home-dad {
  /* background-color: aqua; */
  margin-top: 44px;
}
.main4-home-child1-right img {
  width: 386px;
  height: 270px;
}
.main4-home-left-txt1 {
  width: 36%;
}
.main4-home-left-txt1 h2 {
  color: #555;
  font-size: 1.5em;
  line-height: 1.3;
  font-weight: 900;
  font-family: "poppins-b";
  width: 489px;
  text-align: center;
}
.main4-home-left-txt1 h3 {
  color: #555;
  font-size: 1.25em;
  font-weight: 700;
  font-family: "poppins-b";
  width: 489px;
  text-align: center;
}
.main4-home-left-boxs {
  margin-top: 65px;
  /* width: 570px; */
  justify-content: center;
}
.main4-home-left-box-1 {
  background-color: #087fee;
  box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
  width: 94px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 20px;
  font-family: "poppins-b";
}
.main4-home-left-box-1:hover {
  background-color: #006ad0;
  box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
  width: 94px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 20px;
  font-family: "poppins-b";
}
.main4-home-left-box-1 span {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  cursor: pointer;
  color: white;
}
.main4-home-left-box-1 i {
  color: white;
}
.main4-home-left-box-2 {
  background-color: #ff9822;
  box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
  width: 127px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 20px;
  font-family: "poppins-b";
}
.main4-home-left-box-2:hover {
  background-color: #ee7f01;
  box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
  width: 127px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 20px;
  font-family: "poppins-b";
}
.main4-home-left-box-2 span {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  cursor: pointer;
  color: white;
  font-family: "poppins-b";
}
.main4-home-left-box-2 i {
  color: white;
}
.main4-home-left-box-3 {
  background-color: #9ac272;
  box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
  width: 190px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 20px;
  font-family: "poppins-b";
}
.main4-home-left-box-3:hover {
  background-color: #7a9c59;
  box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
  width: 190px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 99px;
  cursor: pointer;
  padding: 6px 12px;
  margin-right: 20px;
  font-family: "poppins-b";
}
.main4-home-left-box-3 span {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bolder;
  cursor: pointer;
  color: white;
  font-family: "poppins-b";
  text-transform: lowercase;
}
.main4-home-left-box-3 i {
  color: white;
}
.main5-home {
  background-color: white;
  padding-bottom: 50px;
  margin-top: 45px;
}
.main5-icns i {
  color: #006ad0;
  font-size: 22px;
  letter-spacing: -10;
}
.main5-txt1 {
  color: #777;
  width: 595px;
}
.main5-txt2 {
  padding-top: 15px;
}
.main5-txt2 {
  color: #777;
}
.main5-txt2 strong {
  font-family: "poppins-b";
}
.main1-product {
  background-color: white;
  padding-bottom: 25px;
}
.main1-product-dad {
  margin-top: 30px;
}
.main1-product-child1 {
  color: #777;
  text-transform: uppercase;
  font-size: 1em;
  font-family: "poppins-b";
  cursor: pointer;
}
.main1-product-child1:hover {
  color: #ee7f01;
  text-transform: uppercase;
  font-size: 1em;
  font-family: "poppins-b";
}
.main1-product-child1 i {
  font-size: 1.2em;
  margin-right: 4px;
}
.main1-product-child2 {
  color: #777;
  font-size: 1em;
  margin-top: 5px;
}
.main1-anfrage {
  /* height: 250px; */
  background-color: white;
}
.main1-anfrage-dad {
  margin-top: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.main1-anfrage-child1-txt1 {
  font-family: "poppins-b";
  font-size: 1.6em;
  color: #555;
}

.main1-login-child1-txt1 {
  font-family: "poppins-b";
  font-size: 1.6em;
  color: #555;
  text-align: center;
}
.main1-anfrage-child1-txt2 {
  font-size: 1em;
  color: #777;
  margin-top: 10px;
}
.main1-anfrage-child1-txt2 span {
  color: #c02b0a;
  display: inline-block;
  font-size: 13.008px;
}
.main1-anfrage-child1-txt3 {
  font-family: "poppins-b";
  font-size: 1.25em;
  color: #555;
  margin-top: 18px;
}
.main2-anfrage {
  /* height: 500px; */
  background-color: white;
}
.main2-anfrage-dad {
  /* background-color: violet; */
  margin-top: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.main2-login-dad {
  /* background-color: violet; */
  margin-top: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  display: flex;
  justify-content:center;
}
.main2-anfrage-txt1 {
  font-size: 15px;
  color: #222;
  font-weight: bolder;
  font-family: "poppins-b";
}
.main2-anfrage-put input {
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  width: 96%;
  border-radius: 99px;
  padding: 10px 8px;
  margin-top: 5px;
}
.main2-anfrage-select {
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  width: 96%;
  border-radius: 99px;
  padding: 10px 8px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.main2-anfrage-put2 input {
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  width: 100%;
  border-radius: 99px;
  padding: 10px 8px;
  margin-top: 5px;
}
.main2-anfrage-txt1 span {
  color: #c02b0a;
  display: inline-block;
  font-size: 13.008px;
}
.requiredStar {
  color: #c02b0a;
  display: inline-block;
  font-size: 13.008px;
}
.main2-anfrage-txt2 {
  font-size: 15px;
  letter-spacing: normal;
  padding-top: 13px;
  width: 100%;
  color: #777;
}
.main2-anfrage-txtarea {
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  /* width: 100%; */
  border-radius: 10px;
  width: 96%;
  margin-top: 5px;
  margin-bottom: 20px;
}

.main2-buy-txtarea {
  border: 0.5px solid rgba(0, 0, 0, 0.09);
  background-color: rgba(0, 0, 0, 0.05);
  color: #555;
  /* width: 100%; */
  border-radius: 10px;
  width: 100% !important;
  margin-top: 5px;
  margin-bottom: 20px;
}

.main2-anfrage-box1 {
  padding: 32px;
  border: 1px dashed #ccc;
  text-align: center;
  margin-bottom: 8px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin-top: 5px;
}
.main2-anfrage-txt3 {
  font-size: 15px;
  letter-spacing: normal;
  width: 100%;
  color: #777;
  min-width: 200px;
}
.main2-anfrage-btn1 {
  padding-bottom: 6px;
}
.main2-anfrage-btn1 button {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  border-radius: 40px;
  background-color: #ee7f01;
  color: white;
  font-family: "poppins-b";
  margin-top: 8px;
}
.main2-anfrage-btn1 button:hover {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  border-radius: 40px;
  background-color: #cc720b;
  color: white;
  font-family: "poppins-b";
  margin-top: 8px;
}
#main2-anfrage-dad-no-brdr {
  border: none;
}
.main2-anfrage-logo {
  border: 1px solid #d3d3d3;
  background: #f9f9f9;
  color: #000;
  height: 74px;
  width: 300px;
  box-shadow: 0 0 4px 1px rgb(0 0 0 / 8%);
  margin-top: 10px;
}
.main2-anfrage-logo-child1 {
  border-radius: 2px;
  background-color: #fff;
  border: 2px solid #c1c1c1;
  font-size: 1px;
  height: 24px;
  width: 20px;
  /* margin-top: 22px; */
  margin: 0 13px;
}
.main2-anfrage-logo-child2 {
  font-size: 13.5px;
  font-weight: 400;
  line-height: 17px;
  color: black;
  margin-top: 5px;
}
.main2-anfrage-right {
  margin: 8px 13px 0 13px;
}
.main2-anfrage-right-img img {
  width: 32px;
  height: 32px;
  text-align: right;
  margin-right: 8px;
}
.main2-anfrage-right-txt1 {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  margin-top: 5px;
}
.main2-anfrage-right-txt2 {
  font-size: 8px;
  font-weight: 400;
  text-align: right;
  cursor: pointer;
  margin-top: 3px;
}
.main1-product-detail {
  background-color: #fff;
}
.main1-product-detail-dad {
  /* background-color:#7a9c59; */
  margin-top: 50px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 100px;
}
.main1-product-detail-child-left-img1 img {
  /* width: 95%; */
  height: 355px;
  cursor: pointer;
}
.main1-product-detail-child-left-img2 img {
  width: 80px;
  height: 80px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.main1-product-detail-child-left-img2-2 {
  margin-left: 20px;
  border: 0.5px solid white;
}
.main1-product-detail-child-left-img2-2 img {
  width: 80px;
  height: 80px;
  cursor: pointer;
  /* border: none; */
}
.main1-product-detail-child-left-img2-2:hover {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  margin-left: 20px;
}
.main1-product-detail-child1-right-txt1 a {
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85em;
}
.main1-product-detail-child1-right-txt1 a:hover {
  color: black;
  font-size: 0.85em;
}
.main1-product-detail-child1-right-txt1 span {
  color: rgba(102, 102, 102, 0.7);
  font-size: 0.85em;
  margin: 0 0 0.5em;
}
.main1-product-detail-child1-right-txt2 {
  margin-top: 5px;
  font-size: 1.7em;
  color: #555;
  width: 100%;
  font-family: "poppins-b";
}
.main1-product-detail-child1-right-txt3 {
  font-size: 1.5em;
  margin: 10px 0;
  line-height: 1;
  white-space: unset;
  color: #006ad0;
  font-family: "poppins-b";
  width: 100%;
}
.main1-product-detail-child1-right-txt4 {
  color: #222;
  font-family: "poppins-b";
  font-size: 0.9em;
  width: 100%;
}
.main1-product-detail-child1-right-box1 {
  border: 1px #dedede solid;
  margin-bottom: 10px;
  margin-top: 15px;
  /* min-height: 400px; */
  width: 99.8%;
}
.main1-product-detail-child1-right-box1-txt1 {
  margin-bottom: 5px;
  margin-top: 25px;
  color: #ee7f01;
  font-size: inherit;
  font-family: "poppins-b";
  width: 100%;
}
.main1-product-detail-child1-right-box1-txt2 {
  color: #777;
  font-size: 1em;
}
.main1-product-detail-child1-right-box1-1 {
  /* padding: 15px; */
  height: 50px;
  border: 2px dashed #bbb;
  cursor: pointer;
  display: block;
  width: auto;
  clear: both;
  margin-top: 10px;
  margin-bottom: 20px;
}
.main1-product-detail-child1-right-box1-1-icn {
  color: #bbb;
  font-size: 40px;
}
.main1-product-detail-child1-right-box1-1-txt {
  color: #bbb;
  font-size: 1em;
  font-family: "poppins-b";
}
.main1-product-detail-btn1 button {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  border-radius: 40px;
  background-color: #006ad0;
  color: white;
  font-family: "poppins-b";
  margin-top: 15px;
}
.main1-product-detail-btn1 button:hover {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  border-radius: 40px;
  background-color: #1658aa;
  color: white;
  font-family: "poppins-b";
}
.main2-product-detail {
  background-color: white;
  /* height: 500px; */
}
.main2-product-detail-dad {
  /* background-color: rgb(146, 209, 94); */
  border-bottom: 1px solid #ccc;
  padding-bottom: 40px;
}
.main2-product-detail-child1 span {
  border-top: 3px solid #ee7f01;
  font-size: 1.3em;
  color: rgba(17, 17, 17, 0.85);
  font-family: "poppins-b";
}
.main2-product-detail-child1 span:hover {
  color: rgba(17, 17, 17, 0.85);
}
.main2-product-detail-child2 {
  color: #777;
  font-size: 1em;
  width: 100%;
  margin: 16px 0 20px 0;
}
.main3-product-detail {
  background-color: white;
}
.main3-product-detail-dad {
  /* background-color: rgb(219, 172, 43); */
  padding-bottom: 40px;
}
.main3-product-detail-txt {
  font-size: 1.25em;
  color: #555;
  font-family: "poppins-b";
  margin-top: 15px;
}
.main3-product-detail-box {
  width: 23.8%;
  background-color: white;
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
}
.main3-product-detail-box img {
  width: 100%;
}
.chevron-l-icn {
  font-size: 40px;
  top: 160px;
  color: white;
  cursor: pointer;
}
.chevron-l-icn:hover {
  font-size: 40px;
  top: 160px;
  color: #555;
}
.chevron-r-icn {
  font-size: 40px;
  top: 160px;
  right: 30px;
  color: white;
  cursor: pointer;
}
.chevron-r-icn:hover {
  font-size: 40px;
  top: 160px;
  right: 30px;
  color: #555;
}
.main1-impressum {
  background-color: white;
}
.main1-impressum-dad {
  margin-top: 25px;
  padding-bottom: 90px;
}
.main1-impressum-txt1 {
  font-size: 1.7em;
  color: #555;
  width: 100%;
  text-rendering: optimizeSpeed;
  font-family: "poppins-b";
  text-align: center;
}
.main1-impressum-brdr {
  height: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px auto 35px auto;
  width: 100%;
  max-width: 30px;
}
.main1-impressum-txt2 {
  font-size: 1em;
  color: #777;
  width: 100%;
  margin-bottom: 20px;
}
.main1-impressum-txt3 {
  font-size: 1.7em;
  color: #555;
  width: 100%;
  font-family: "poppins-b";
  margin-bottom: 5px;
}
.main1-impressum-txt4 {
  font-size: 1.6em;
  color: #555;
  width: 100%;
  font-family: "poppins-b";
  margin-bottom: 10px;
}
.main1-impressum-txt5 {
  font-size: 1em;
  color: #777;
  width: 100%;
  margin-bottom: 20px;
}
.main1-impressum-txt5 strong {
  color: #777;
  width: 100%;
  font-family: "poppins-b";
}
.main1-impressum-txt6 {
  font-size: 1em;
  color: #777;
  width: 100%;
  margin-top: -20px;
}
.main1-warenkorb {
  background-color: #f7f7f7;
}
.main1-warenkorb-dad {
  margin-top: 30px;
  padding-bottom: 40px;
}
.main1-warenkorb-txt1 {
  font-size: 1.125em;
  color: #555;
  width: 100%;
  text-rendering: optimizeSpeed;
  font-family: "poppins-b";
  text-align: center;
}
.main1-warenkorb-child1 {
  border-bottom: 1px solid #ccc;
  /* height: 90px; */
}
.main1-warenkorb-child1-right-img img {
  width: 60px;
  height: 60px;
  padding-bottom: 23px;
  cursor: pointer;
}
.main1-warenkorb-child1-txt-up a {
  margin-bottom: 5px;
  text-overflow: ellipsis;
  color: #555555;
  font-size: 1em;
  margin-left: 1em;
}
.main1-warenkorb-child1-icn-up {
  margin-bottom: -30px;
}
.main1-warenkorb-child1-icn-up i {
  font-size: 30px;
  color: #ccc;
  cursor: pointer;
}
.main1-warenkorb-child1-icn-up i:hover {
  font-size: 30px;
  color: black;
  cursor: pointer;
}
.main1-warenkorb-child1-icn-down {
  font-size: 0.85em;
  opacity: 0.6;
  color: #777;
  margin-left: 1em;
}
.main1-warenkorb-child1-icn-down span {
  color: #006ad0;
  font-family: "poppins-b";
}
#main1-warenkorb-child1-brdr2 {
  border-bottom: 1px solid #ececec;
}
.main1-warenkorb-child2-txt1 {
  border-bottom: 2px solid #ececec;
  padding-bottom: 10px;
  margin-top: -10px;
  font-family: "poppins-b";
  font-size: 1em;
  color: #777;
}
.main1-warenkorb-child2-txt1 span {
  color: #000;
}
.main1-warenkorb-child2-btn1 {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  width: 100%;
  background-color: #ee7f01;
  color: white;
  font-family: "poppins-b";
  margin-top: 15px;
}
.main1-warenkorb-child2-btn1 a {
  color: white;
}
.main1-warenkorb-child2-btn1:hover {
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  width: 100%;
  color: white;
  font-family: "poppins-b";
  margin-top: 15px;
  background-color: #ce7006;
}
.main1-warenkorb-child2-btn1 a:hover {
  color: white;
}
.main1-warenkorb-child2-btn2 {
  font-size: 100%;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  /* padding: 0 1.2em; */
  width: 100%;
  background-color: #006ad0;
  color: white;
  font-family: "poppins-b";
  margin-top: 8px;
}
.main1-warenkorb-child2-btn2 a {
  color: white;
}
.main1-warenkorb-child2-btn2:hover {
  font-size: 100%;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 2.4em;
  min-height: 2.5em;
  /* padding: 0 1.2em; */
  width: 100%;
  color: white;
  font-family: "poppins-b";
  margin-top: 8px;
  background-color: #1658aa;
}
.main1-warenkorb-child2-btn2 a:hover {
  color: white;
}
.main1-warenkorb-detail {
  background-color: white;
}
.main1-warenkorb-detail-dad {
  width: 63%;
  margin-top: 40px;
  padding-bottom: 30px;
}
.main1-warenkorb-detail-numbr {
  background-color: #006ad0;
  color: #fff;
  margin: 0 0.5em;
  font-family: "poppins-b";
  text-align: center;
  height: 1.5em;
  width: 1.5em;
  font-size: 1.1em;
  line-height: 1.5em;
  border-radius: 99px;
  margin-top: 7px;
  cursor: pointer;
}
.main1-warenkorb-detail-txt1 a {
  touch-action: manipulation;
  color: #111;
  font-size: 1.5em;
}
.main1-warenkorb-detail-txt1 a:hover {
  color: black;
}
.main1-warenkorb-detail-icn1 {
  margin: 3.5px 15px 0 15px;
  color: #999;
  font-size: 1.2em;
}
.main1-warenkorb-detail-numbr2 {
  background-color: #ccc;
  color: #fff;
  margin: 0 0.5em;
  font-family: "poppins-b";
  text-align: center;
  height: 1.5em;
  width: 1.5em;
  font-size: 1.1em;
  line-height: 1.5em;
  border-radius: 99px;
  margin-top: 7px;
  cursor: pointer;
}
.main1-warenkorb-detail-txt2 a {
  touch-action: manipulation;
  color: #ccc;
  font-size: 1.5em;
}
.main1-warenkorb-detail-txt2 a:hover {
  color: black;
}
#main1-warenkorb-detail-txt2-hvr:hover {
  color: #ccc;
}
.main2-warenkorb-detail {
  background-color: white;
}
.main2-warenkorb-detail-dad {
  margin-top: 5px;
  padding-bottom: 80px;
}
.main2-warenkorb-detail-child1 {
  width: 57.5%;
  border-right: 1px solid #ececec;
}
.main2-warenkorb-detail-child1-left1 {
  border-bottom: 3px solid #ececec;
  font-size: 0.9em;
  color: #777;
  font-family: "poppins-b";
  padding-bottom: 3px;
}
.main2-warenkorb-detail-child1-left2 {
  border-bottom: 1px solid #ececec;
  padding-bottom: 15px;
}
.main2-warenkorb-detail-child1-left2-icn1 {
  margin-top: 15px;
}
.main2-warenkorb-detail-child1-left2-icn1 i {
  font-size: 30px;
  color: #ccc;
  cursor: pointer;
}
.main2-warenkorb-detail-child1-left2-icn1 i:hover {
  font-size: 30px;
  color: black;
  cursor: pointer;
}
.main2-warenkorb-detail-child1-left2-img1 img {
  width: 76px;
  height: 76px;
  cursor: pointer;
  padding: 0 9px;
}
.main2-warenkorb-detail-child1-left2-txts a {
  font-size: 1em;
  color: #555;
  font-family: "poppins-b";
  /* width: 100%; */
}
.main2-warenkorb-detail-child1-left2-txts {
  font-size: 0.9em;
  color: #666;
  width: 100%;
}
.main2-warenkorb-detail-child1-left2-txt2 {
  color: #999;
  width: 100%;
  font-size: 0.8em;
  margin-top: -5px;
  padding-top: 7px;
}
.main2-warenkorb-detail-child1-right2-txt1 {
  font-size: 0.9em;
  color: #006ad0;
  font-family: "poppins-b";
  width: 100%;
  margin-top: 30px;
}
.main2-warenkorb-detail-child2 {
  width: 42.5%;
  /* position: relative;
    transform: translate3d(0px, 0px 0px); */
}
.main2-warenkorb-detail-child2-right1-txts {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}
.main2-warenkorb-detail-child2-right1-txt1 {
  color: #333;
  width: 100%;
  font-size: 0.9em;
  font-family: "poppins-b";
  text-align: right;
}
.main2-warenkorb-detail-child2-right1-txt2 {
  color: #777;
  width: 100%;
  font-size: 0.9em;
  text-align: left;
}
.main2-warenkorb-detail-child2-right2-txt1 {
  margin-top: 10px;
  font-size: 0.8em;
  color: #777;
  cursor: default;
}
.main2-warenkorb-detail-child2-right2-txt1:hover {
  margin-top: 10px;
  font-size: 0.8em;
  color: #222;
}
.main2-warenkorb-detail-child2-right2-txt2 {
  font-size: 0.9em;
  color: #777;
  line-height: 0.5;
}
.main2-warenkorb-detail-child2-right2-txt3 {
  font-size: 0.8em;
  color: #777;
  padding-bottom: 10px;
}
.main2-warenkorb-detail-child2-right2-txt3 span {
  font-size: 1em;
  color: #777;
  font-family: "poppins-b";
}
.main2-warenkorb-detail-child2-right2-txts {
  border-bottom: 3px solid #ccc;
  padding-bottom: 5px;
  margin-top: 5px;
}
.main2-warenkorb-detail-child3-icn1 {
  border-bottom: 3px solid #ccc;
  font-size: 0.95em;
  padding-bottom: 10px;
  margin-top: 20px;
  color: #555;
  font-family: "poppins-b";
}
.main2-warenkorb-detail-child3-icn1 i {
  color: #999;
  font-size: 1.1em;
  padding-right: 5px;
}
.main2-warenkorb-detail-child3-input {
  margin-top: 20px;
  transition: color 0.3s, border 0.3s;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.507em;
  font-size: 0.97em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  text-align: left;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}
.main2-warenkorb-detail-child3-input:hover {
  margin-top: 20px;
  transition: color 0.3s, border 0.3s;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.507em;
  font-size: 0.97em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  text-align: left;
  box-shadow: none;
}
.main2-warenkorb-detail-child3-input::placeholder {
  color: #777;
  font-family: "poppins";
}
.main2-warenkorb-detail-child3-submit {
  margin-top: 15px;
  font-family: "poppins";
  background-color: #f9f9f9;
  text-shadow: 1px 1px 1px #fff;
  color: #999;
  border: 1px solid #ddd;
  text-transform: none;
  font-size: 0.97em;
  padding: 0 0.75em;
  height: 2.507em;
  max-width: 100%;
  width: 100%;
  cursor: pointer;
}
.main2-warenkorb-detail-child3-submit:hover {
  margin-top: 15px;
  font-family: "poppins";
  background-color: rgb(201, 200, 200);
  text-shadow: 1px 1px 1px #fff;
  color: #999;
  border: 1px solid rgb(201, 200, 200);
  transition: 0.5s;
  text-transform: none;
  font-size: 0.97em;
  padding: 0 0.75em;
  height: 2.507em;
  max-width: 100%;
  width: 100%;
  cursor: pointer;
}
.main1-kasse {
  background-color: white;
}
.main1-kasse-dad {
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.main1-kasse-child1 {
  width: 60%;
}
.main1-kasse-child1-txt1 {
  border-bottom: 2px solid #ccc;
  color: #777;
  padding-bottom: 0.5em;
}
.main1-kasse-child1-txt2 {
  color: #555;
  margin-top: 20px;
  font-family: "poppins-b";
  font-size: 1.1em;
  padding-bottom: 10px;
}
.main1-kasse-child1-box1 {
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.507em;
  font-size: 0.97em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  height: 3.0084em;
}
.main1-kasse-child1-box1 label {
  transition: all 0.3s;
  font-size: 12px;
  color: #777;
}
.main1-kasse-child1-box1 input {
  width: 100%;
  border: none;
  font-size: 1em;
  color: #333;
  transition: all 0.3s;
  margin-top: -6px;
  font-family: "poppins";
  background: none;
  margin-left: -2px;
}
.main1-kasse-child1-box1:hover {
  box-shadow: none;
}
.main1-kasse-child1-txt3 {
  display: block;
  font-size: 0.9em;
  color: #222;
  font-family: "poppins-b";
  margin-top: 25px;
}
.main1-kasse-child1-txt4 {
  color: #555;
  margin-top: 5px;
  font-family: "poppins-b";
  font-size: 1.1em;
  padding-bottom: 10px;
}
.main1-kasse-child1-box2 {
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.507em;
  font-size: 0.97em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  height: 3.0084em;
}
.main1-kasse-child1-box2:hover {
  box-shadow: none;
}
.main1-kasse-child1-box2 input {
  /* margin-top: 10px; */
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  font-family: "poppins";
  font-size: 1em;
  color: #777;
}
.main1-kasse-child1-box3 span {
  font-size: 1em;
  color: #222;
  cursor: default;
}
.main1-kasse-textarea {
  padding-top: 0.7em;
  min-height: 120px;
  margin-top: 20px;
  border: 1px solid #ddd;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}
.main1-kasse-textarea:hover {
  box-shadow: none;
}
.main1-kasse-textarea::placeholder {
  color: #777;
  font-size: 1.2em;
  font-family: "poppins";
  padding-right: 5px;
}
.main2-kasse-child2 {
  width: 40%;
  border: 2px solid #ee7f01;
  margin-top: 32px;
}
.main2-kasse-child2-txt1 {
  color: #555;
  width: 100%;
  font-size: 1.1em;
  font-family: "poppins-b";
}
.main2-kasse-child2-txt2 a {
  font-size: 1em;
  color: #555;
}
.main2-kasse-child2-txt2 {
  font-size: 1em;
  color: #666;
  width: 100%;
}
.main2-kasse-child2-txt3 {
  color: #999;
  width: 100%;
  font-size: 0.9em;
  margin-top: -5px;
  padding-top: 7px;
}
.main2-kasse-child2-txt4 {
  font-size: 0.9em;
  color: #006ad0;
  font-family: "poppins-b";
  width: 100%;
  margin-top: 20px;
}
.main2-warenkorb-detail-child2-right1-txt2-2 {
  color: #777;
  width: 100%;
  font-size: 0.9em;
  text-align: left;
  font-family: "poppins-b";
  padding: 5px 0 3px 0;
}
.main2-warenkorb-detail-child2-right1-txt1-1 {
  font-size: 0.8em;
  color: #777;
  cursor: default;
  padding: 11px 0 8px 0;
}
.main2-warenkorb-detail-child2-right1-txt1-1:hover {
  font-size: 0.8em;
  color: #222;
  padding: 11px 0 8px 0;
}
#versand-txt-p {
  padding-top: 10px;
}
.main2-warenkorb-detail-child2-right1-txt1-2 {
  color: #333;
  width: 100%;
  font-size: 0.9em;
  font-family: "poppins-b";
  text-align: right;
  padding: 5px 0 3px 0;
}
.main2-kasse-child3-txt5 {
  font-size: 85%;
  color: #777;
  width: 100%;
  padding-bottom: 30px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-width: 150px;
  margin: 0px 5px;
  text-align: center;
  font-family: arial;
}

.card h1 {
  font-size: 25px;
}

.card button {
  font-size: 16px;
}
.price {
  color: grey;
  font-size: 22px;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #b67237;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.card button:hover {
  opacity: 0.7;
}

.card a {
  font-size: 16px;
}
.rs-input[disabled] {
  color:#8e8e93 !important;
}
.ribbon {
  position: relative;
}
.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
}
.ribbon-txt {
  font-size: 7.5px;
  font-weight: bold;
  color: #333;
  text-align: center;
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  top: 15px;
  left: -7px;
  width: 120px;
  background-color: #ee7f01;
  color: #fff;
}

.percent-loader .rs-loader-spin {
  display: none !important;
}

.loader-progress {
  height: 120vh;
  position: absolute;
  background-color: white;
  width: 100vw;
  z-index: 9999;
  opacity: 0.8;
  align-items: center;
}
